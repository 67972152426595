exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-benefits-tsx": () => import("./../../../src/pages/benefits.tsx" /* webpackChunkName: "component---src-pages-benefits-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-statement-tsx": () => import("./../../../src/pages/privacy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blog-list-blog-list-tsx": () => import("./../../../src/templates/blog-list/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-blog-list-tsx" */),
  "component---src-templates-category-category-tsx": () => import("./../../../src/templates/category/category.tsx" /* webpackChunkName: "component---src-templates-category-category-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/post/post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */),
  "component---src-templates-tag-tag-tsx": () => import("./../../../src/templates/tag/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tag-tsx" */)
}

